<template>
  <div class="hello">
   
    <div class="mb-4 header">
      <h1>Welcome to</h1>
      <h1 :style="{color:color}">&#160;ghoulchat</h1>
    </div>
    <p class="m-4">
      Chat with your friends anonymously.
    </p>

    <div class="form">
          <a class="m-4" href="https://apps.apple.com/us/app/ghoulchat/id1607021397">
                <img src="../assets/app.svg" class="appImg" />
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

  computed: {
  
    color(){
      return "#4CD964"
    }
  }

}
</script>
<style scoped>

.appImg{
    width: 200px;
}

.form:hover{
    opacity: 0.8;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #4CD964;
}

.header{
  display: flex;
  align-items: center;
  justify-content: center;
}
.form{
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .form{ 
     max-width: 80%;
  }
}
</style>
