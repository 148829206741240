<template>
  <div class="hello">
    <h1>We're sorry</h1>
    <p>
        That page doesn't exist. 
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  created() {
    window.location.href =  "https://apps.apple.com/us/app/ghoulchat/id1607021397";
  }
}
</script>

