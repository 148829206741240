<template>
  <div class="home">
    <NotFoundPage/>
  </div>
</template>

<script>
// @ is an alias to /src
import NotFoundPage from '@/components/NotFoundPage.vue'

export default {
  name: '404',
  components: {
    NotFoundPage
  }
}
</script>
